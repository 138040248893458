// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';

// Bootstrap Icons
@import '~bootstrap-icons/font/bootstrap-icons';

// Template fonts
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "../fonts/material.css";
@import "../fonts/tabler-icons.min.css";
@import "../fonts/feather.css";

// Template css
@import "../css/customizer.css";
@import "../css/style.css";

@import "~jquery-ui/themes/base/selectable.css";
a{
    text-decoration:none;
}

#toast-main{
    z-index: 2000;
}

$temp: 200px;
.pc-sidebar {
    width: $temp;
}
.pc-header, .page-header{
    left: $temp;
}
.pc-container{
    margin-left: $temp;
}

@media (max-width: 1024px) {
    .pc-header, .page-header{
        left: 0;
    }
    .pc-container{
        margin-left: 0;
    }
}

